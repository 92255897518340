body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  outline-color: #f94e6d !important;
}

#print-btn {
  font-family: 'Roboto Mono', monospace;
}

#error-msg {
  min-height: 4rem;
}

body {
  overflow-x: hidden;
  min-height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #F94E6D;
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* a,
a:hover,
a:focus,
a:active,
a:visited {
  font-family: 'Roboto Mono', monospace;
  color: #222;
  font-weight: 700;
  text-decoration: underline;
  transition: all .3s;
} */

/* a:hover,
a:focus,
a:active {
  color: #F94E6D;
} */

nav {
  position: fixed;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* background: #222; */
  z-index: 300;
}

/* nav h3 {
  font-size: 1.5rem;
  color: white;
  font-family: 'Roboto Mono', monospace;
  margin: 0;
} */

/* nav a,
nav a:hover,
nav a:focus,
nav a:active,
nav a:visited {
  font-weight: normal;
  text-decoration: none;
} */

/* nav a,
nav a:visited {
  color: white;
} */

/* nav a:hover,
nav a:focus {
  color: #F94E6D;
} */

/* a.active-link {
  color: #F94E6D !important;
} */

/* main {
  padding-top: 4rem;
  min-height: calc(100vh - 7.6rem);
  overflow-x: hidden;
} */

/* main ~ footer {
  color: white;
} */

.jumbotron {
  background: transparent;
  padding: 0;
  margin: 0;
}

.top span,
.bottom span {
  opacity: 0;
}

.top,
.bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.bottom {
  margin-top: -3.625rem;
}

.top {
  margin-left: 2.1rem;
  margin-right: 2.1rem;
}

.top span,
.bottom span {
  width: 0;
  height: 0;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  margin: .1rem;
  -webkit-transform: scale(.5);
          transform: scale(.5);
  -webkit-transition: .5s;
  transition: .5s;
}

.top span {
  border-top: 3.464rem solid transparent;
}

.bottom span {
  border-bottom: 3.464rem solid transparent;
}

.bottom span:first-child {
  display: none;
}

.bottom span:hover,
.bottom span:focus,
.top span:hover,
.top span:focus {
  -webkit-transform: scale(1);
          transform: scale(1);
}

#feature,
#welcome,
#work {
  margin-bottom: 0;
  padding-bottom: 0;
}

#welcome h1 {
  color: #181717;
  /* font-size: 1.8rem; */
}

#welcome h5 {
  color: #8B8B8B;
  /* font-size: 1rem; */
}

#welcome a {
  text-decoration: none;
}

#welcome .more {
  width: 10rem;
}

#welcome .more span {
  display: none;
}

#welcome .more:hover span,
#welcome .more:focus span {
  display: inline;
}

.logo {
  width: 98%;
}

.tech-wrap {
  width: 3rem;
  height: 3rem;
  max-width: calc(100vw / 10);
  max-height: calc(100vw / 10);
  border: 2px solid #000;
  border-radius: 50%;
  -webkit-filter: invert(1);
          filter: invert(1);
  margin: .25rem;
  -webkit-transition: .2s;
  transition: .2s;
}

.tech-wrap svg {
  width: 100%;
  padding: 25%;
  -webkit-transition: fill .2s;
  transition: fill .2s;
}

.tech-wrap:hover,
.tech-wrap:focus {
  /* border-color: #222; */
  -webkit-filter: invert(0);
          filter: invert(0);
}

#html5 {
  background-color: #1CB0D9;
}

#css3 {
  background-color: #EA8D49;
}

#bootstrap {
  background-color: #A9C283;
}

#javascript {
  background-color: #0820E1;
}

#jquery {
  background-color: #F89652;
}

#react {
  background-color: #9E2504;
}

#android {
  background-color: #5B39C6;
}

#nodejs {
  background-color: #CC66CC;
}

#mongodb {
  background-color: #B85DB7;
}

#npm {
  background-color: #34C7C8;
}

#python {
  background-color: #C88954;
}

#git {
  background-color: #0FAFCD;
}

#github {
  background-color: #E7E8E8;
}

#javascript svg {
  fill: #fff;
}

#react svg {
  fill: #fff;
}

#android svg {
  fill: #fff;
}

#html5:hover svg {
  fill: #E34F26;
}

#css3:hover svg {
  fill: #1572B6;
}

#bootstrap:hover svg {
  fill: #563D7C;
}

#javascript:hover svg {
  fill: #F7DF1E;
}

#jquery:hover svg {
  fill: #0769AD;
}

#react:hover svg {
  fill: #61DAFB;
}

#android:hover svg {
  fill: #A4C639;
}

#nodejs:hover svg {
  fill: #339933;
}

#mongodb:hover svg {
  fill: #47A248;
}

#npm:hover svg {
  fill: #CB3837;
}

#python:hover svg {
  fill: #3776AB;
}

#git:hover svg {
  fill: #F05032;
}

#github:hover svg {
  fill: #181717;
}

.about-wrapper,
.contact-wrapper,
.success-wrapper,
.error-wrapper,
#resume {
  opacity: 0;
  -webkit-animation: fadein .5s forwards;
          animation: fadein .5s forwards;
}

#work {
  opacity: 0;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  -webkit-animation: fadein .5s 2.5s forwards;
          animation: fadein .5s 2.5s forwards;
}

#animations .cp_embed_wrapper {
  height: 352px;
}

#animations .codepen {
  height: 350px;
}


#fizzbuzz .cp_embed_wrapper {
  height: 277px;
}

#fizzbuzz .codepen {
  height: 275px;
}

.codepen {
  padding: 100px 1rem;
  background: #FEE5E9;
  text-align: center;
}

.cp_embed_wrapper {
  background: #FEE5E9;
}

#bojack-frame {
  height: 90vh;
}

.more {
  font-size: 1.25rem;
  padding: .5rem 1rem;
  background-color: #F94E6D;
  border: 3px solid #222;
  text-decoration: none !important;
}

.more:hover,
.more:focus {
  background-color: #222;
}

#about-img-wrapper {
  width: 200px;
  height: 200px;
  background-color: #F94E6D;
  border: 5px solid transparent;
  border-radius: 50%;
}

#about img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  -webkit-filter: hue-rotate(150deg) contrast(1.5) grayscale(.3);
          filter: hue-rotate(150deg) contrast(1.5) grayscale(.3);
}

#resume-header img {
  width: 100%;
}

#card {
  display: none;
}

#skills-graph {
  width: 100%;
  max-width: 35rem;
}

.twitter-wrap {
  height: 2rem;
  opacity: 0;
  -webkit-animation: fadein 2s forwards;
          animation: fadein 2s forwards;
}

footer .row {
  padding: 0 .75rem;
}

.social-icon {
  width: 2rem;
  height: 2rem;
  margin-right: .25rem;
  margin-left: .25rem;
  background-color: #222;
  border-radius: 50%;
  border: .1rem solid #222;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.social-icon:hover {
  background-color: #fff;
}

.social-icon a svg {
  height: 100%;
  padding: .275rem;
}

.social-icon a path {
  fill: #fff;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

#twitter-icon:hover path {
  fill: #1DA1F2;
}

#linkedin-icon:hover path {
  fill: #0077B5;
}

#github-icon:hover path {
  fill: #181717;
}

#codepen-icon.social-icon:hover path {
  fill: #000000;
}

#treehouse-icon:hover path {
  fill: #5FCF80;
}

@media (min-width: 768px) {

  nav {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
  }

  nav h3 {
    font-size: 1.75rem;
  }

  /* main {
    min-height: calc(100vh - 5.6rem);
  } */

  .logo {
    width: 75%;
    max-width: 800px;
  }

  #welcome h1 {
    color: #181717;
    font-size: 4rem;
  }
  
  #welcome h6 {
    color: #8B8B8B;
    font-size: 1.5rem;
  }

  .tech-wrap {
    height: 4.5rem;
    width: 4.5rem;
    /* margin: .5rem; */
    border-width: 3px;
  }

  #card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    width: 15rem;
    height: 20rem;
    margin: 0 0 0 auto;
    background: #FDCAD3;
    border-radius: .75rem;
  }

  #card a {
    text-decoration: none;
  }

  #experience {
    max-width: 15rem;
    margin: 0 0 0 auto;
  }

  #card-top {
    width: 100%;
    height: 12rem;
  }

  #card-bottom {
    width: 100%;
    height: 8rem;
  }

  #card-photo {
    width: 12rem;
    height: 12rem;
    border-top-left-radius: .75rem;
    /* filter: hue-rotate(150deg) contrast(1.5) grayscale(.3); */
  }

  #card-top ul {
    width: 3rem;
  }

  #card-bottom ul {
    width: 100%;
  }

  #card li {
    font-size: .85rem;
  }

  #card i {
    font-size: .85rem;
    padding-right: .65rem;
  }
}

@media (min-width: 1000px) {
  .top span:nth-child(7),
  .bottom span:nth-child(7) {
    margin-left: auto;
  }
  
  .top span:nth-child(6),
  .bottom span:nth-child(6) {
    margin-right: auto;
  }

  #about-img-wrapper {
    width: 300px;
    height: 300px;
  }
}

@-webkit-keyframes fadein {
  to {opacity: 1;}
}

@keyframes fadein {
  to {opacity: 1;}
}
